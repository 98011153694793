@media screen and (max-width: 899px) {
  /*
  =====================================================================
  *
  *    Default
  *
  * ===================================================================
  */
  html,
  body {
    width: auto;
    min-width: auto;
  }
  blockquote::before {
    top: -65px !important;
  }
  .c-subHeader,
  .l-header_wrap,
  .heroImage_button,
  .swiper-button-next,
  .swiper-button-next-bg,
  .swiper-button-prev,
  .swiper-button-prev-bg,
  .l-topArticles.monthlyArticles .popcorn01,
  .l-topArticles.monthlyArticles .popcorn02,
  .l-topArticles.monthlyArticles .milky,
  .l-topArticles.monthlyArticles .poprina,
  .c-footerAddress,
  .l-footerContents_right_nav,
  .c-pageHeader_bredcrumb,
  .shopMap {
    display: none !important;
  }
  .l-topArticles,
  .c-sectionTitle,
  .l-subFooter_info,
  .l-subFooter_bnr,
  .l-footerContents,
  .l-footerContents_left,
  .l-footerContents_right,
  .l-company,
  .l-about {
    width: 100%;
  }
  .l-header {
    min-width: auto;
    overflow: auto;
  }
  .l-header_inner {
    background: none;
    padding: 0;
  }
  .l-header_inner .l-topArticles.heroImages {
    padding: 80px 0 32px 0px;
    overflow: hidden;
  }
  .c-heroImage_list {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .c-heroImage_list img {
    width: 100%;
    height: auto;
    box-shadow: none;
  }
  .swiper-pagination.heroImage {
    bottom: 6px;
  }
  .swiper-pagination.heroImage .swiper-pagination {
    display: none;
  }
  .swiper-pagination.heroImage .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    margin: 0 5px;
  }
  .l-topSection.flavorSection {
    margin: 0 0 90px 0;
    padding: 30px 20px 50px 20px;
  }
  .l-topSection.topicsSection {
    padding: 25px 0px 15px 0;
  }
  .l-topSection.monthlySection {
    background-position: center top;
    padding: 30px 10px 0 10px;
  }
  .l-topArticles.monthlyArticles {
    height: auto;
    padding: 0 0 30px 0;
    overflow: hidden;
  }
  .l-topArticles.magazineArticles {
    overflow: hidden;
    min-height: auto;
  }
  .c-primaryBtn.topMagazine {
    display: none;
  }
  .l-flavor {
    padding: 20px;
  }
  .l-about {
    margin: -15px 0 80px;
  }
  .l-company {
    padding: 0 0 20px 0;
  }
  .l-companyTop {
    margin: 0 auto 40px auto;
  }
  .l-character {
    padding: 60px 20px 90px 20px;
  }
  .l-staff {
    padding: 20px;
  }
  .l-privacy {
    font-size: 12px;
    padding: 20px;
  }
  .l-mediaArticles {
    width: auto;
  }
  .l-mediaSection {
    margin: 0 10px 30px 10px;
    padding: 20px 0;
  }
  .l-404 .p-pageHeader_title {
    font-size: 26px;
  }
  .p-flavorArticle_tmb {
    width: 125px;
    height: 125px;
  }
  .p-flavorArticle_tmb img {
    width: 125px;
    height: 125px;
  }
  .p-flavorArticle_title {
    font-size: 14px;
    margin: 10px 0 5px 0;
  }
  .p-topicsArticle {
    width: auto;
    padding: 0 10px;
  }
  .p-topicsArticle_tmb {
    width: 140px;
    height: 140px;
  }
  .p-topicsArticle_tmb img {
    max-width: 140px;
    max-height: 140px;
  }
  .p-topicsArticle_body {
    padding: 0 0 0 12px;
  }
  .p-topicsArticle_body_date {
    display: none;
  }
  .p-topicsArticle_body_title {
    max-height: 40px;
    overflow: hidden;
    font-size: 15px;
    line-height: 1.4;
    margin: 3px 0 0 0;
  }
  .p-topicsArticle_body_desc {
    height: 54px;
    line-height: 1.4;
  }
  .p-topicsArticle:last-child {
    margin: 0;
  }
  .p-flavor {
    overflow: hidden;
    margin: 0;
  }
  .p-flavor_item {
    float: left;
    width: 120px;
    min-height: 170px;
    margin: 0 10px 20px 10px;
  }
  .p-flavor_item a img {
    width: 120px;
    height: 120px;
  }
  .p-flavor_item:nth-child(4n) {
    margin: 0 10px 20px 10px;
  }
  .p-monthlyArticle {
    float: left;
    width: 48%;
    margin: 0 0 20px 0;
  }
  .p-monthlyArticle:first-child {
    margin: 0 0 20px 0;
  }
  .p-monthlyArticle:nth-child(2n) {
    float: right;
  }
  .p-monthlyArticle_tmb {
    width: 100%;
    height: auto;
  }
  .p-monthlyArticle_tmb img {
    width: 100%;
    max-width: none;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
  .p-monthlyArticle_body {
    padding: 8px 10px 15px 10px;
  }
  .p-monthlyArticle_body_desc {
    margin: 0;
    height: auto;
    line-height: 1.4;
  }
  .p-monthlyArticle_body_desc a {
    display: none;
  }
  .p-smileArticle:first-child {
    margin-left: 10px;
  }
  .p-smileArticle:last-child {
    margin-right: 10px;
  }
  .p-magazineArticle,
  .p-shopArticle {
    float: left;
    width: 48%;
    min-height: 415px;
    margin: 0 0 20px 10px;
  }
  .p-magazineArticle:nth-child(2n),
  .p-shopArticle:nth-child(2n) {
    float: right;
    margin: 0 10px 20px 0;
  }
  .p-magazineArticle:nth-child(4n),
  .p-shopArticle:nth-child(4n) {
    float: right;
    margin: 0 10px 20px 0;
  }
  .p-magazineArticle_tmb,
  .p-shopArticle_tmb {
    width: 100%;
    height: auto;
  }
  .p-magazineArticle_tmb img,
  .p-shopArticle_tmb img {
    width: 100%;
    max-width: none;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
  .p-magazineArticle_body_title,
  .p-shopArticle_body_title {
    height: 45px;
  }
  .p-magazineArticle_body_customTable,
  .p-shopArticle_body_customTable {
    width: 100%;
  }
  .p-magazineArticle_body_customTable th,
  .p-shopArticle_body_customTable th {
    width: 80px;
  }
  .p-magazineArticle_body_btnGroup,
  .p-shopArticle_body_btnGroup {
    text-align: left;
  }
  .p-magazineArticle_body_btnGroup .c-primaryBtn,
  .p-shopArticle_body_btnGroup .c-primaryBtn {
    width: 140px;
    margin: 0 0 10px 0;
  }
  .p-magazineArticle_body_btnGroup .c-primaryBtn:last-child,
  .p-shopArticle_body_btnGroup .c-primaryBtn:last-child {
    margin: 0;
  }
  .p-shopArticle_tmb {
    width: 100%;
    height: 200px;
  }
  .p-aboutHero {
    height: auto;
    margin: 0 0 20px 0;
  }
  .p-aboutHero_image {
    width: 100%;
    height: auto;
  }
  .p-aboutTitle {
    height: auto;
    margin: 0 0 30px 0;
  }
  .p-aboutTitle img {
    opacity: 1;
    width: 75%;
    height: auto;
  }
  .p-aboutTitle img.aboutAnimation1 {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
  .p-aboutTitle img.aboutAnimation2, .p-aboutTitle img.aboutAnimation3 {
    display: none;
  }
  .p-aboutArticle {
    display: block;
    margin: 0 0 60px 0;
    padding: 0;
  }
  .p-aboutArticle_item.aboutContents {
    margin: 0 10px;
  }
  .p-aboutArticle_item.aboutTmb {
    width: 100%;
    height: auto;
    margin: 0;
  }
  .p-aboutArticle_item.aboutTmb .milky,
  .p-aboutArticle_item.aboutTmb .choco,
  .p-aboutArticle_item.aboutTmb .chocoHand,
  .p-aboutArticle_item.aboutTmb .poprina,
  .p-aboutArticle_item.aboutTmb .johnny {
    display: none !important;
  }
  .p-aboutArticle_item.aboutTmb img {
    display: none;
  }
  .p-aboutArticle_item_title {
    margin: 0 0 10px 0;
  }
  .p-aboutArticle_item_title img {
    width: auto;
    height: 45px;
  }
  .p-aboutArticle_item .spAboutTmb {
    display: block;
    margin: 0 0 20px 0;
  }
  .p-aboutArticle_item .spAboutTmb img {
    width: 100%;
    height: auto;
  }
  .p-aboutArticle .c-primaryBtn {
    display: block;
    width: 180px;
    font-size: 13px;
    margin: 0 auto 10px auto;
    padding: 10px 20px;
  }
  .p-characterArticle {
    display: none;
  }
  .p-companyHero {
    height: 200px;
    margin: 5px 0 30px 0;
  }
  .p-companyHero_image {
    width: auto;
    height: 50px;
  }
  .p-mediaArticle {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px dotted #CCCCCC;
  }
  .p-mediaArticle_tmb {
    width: 120px;
    height: 120px;
  }
  .p-mediaArticle_tmb img {
    width: 120px;
    height: 120px;
  }
  .p-mediaArticle_body {
    vertical-align: top;
  }
  .p-mediaArticle_body_date {
    display: block;
    margin: 0 0 5px 0;
  }
  .p-mediaArticle_body_title {
    overflow: hidden;
    height: 94px;
    margin: 0;
    line-height: 1.4;
    font-size: 14px;
  }
  .p-mediaArticle_body_desc {
    display: none;
  }
  .p-mediaArticle:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .p-companyRecruit {
    height: 153px;
    padding: 60px 0;
  }
  .p-companyRecruit_title {
    font-size: 22px;
    letter-spacing: 1px;
    margin: 0 0 30px 0;
    padding: 0 40px;
  }
  .p-404_image img {
    width: 300px;
  }
  .c-companyTop_item {
    display: none;
  }
  .flavorBtn {
    font-size: 15px;
    width: 270px;
    padding: 15px 15px 15px 45px;
  }
  .c-sectionTitle.flavorTitle {
    width: auto;
    margin: 0 0 15px 0;
  }
  .c-sectionTitle.flavorTitle img {
    width: auto;
    height: 35px;
  }
  .c-sectionTitle_link.magazine {
    display: block;
  }
  .c-sectionTitle span {
    padding: 0 0 0 10px;
  }
  .c-sectionTitle span img {
    width: auto;
    height: 35px;
  }
  .c-sectionTitle.info .c-sectionTitle_link {
    margin: 0;
  }
  .c-sectionTitle .c-sectionTitle_link {
    margin: 0 10px 8px 0;
  }
  .c-pageHeader {
    max-width: none;
    padding: 95px 10px 0px 10px;
  }
  .c-pageHeader_title img {
    width: auto;
    height: 35px;
  }
  .c-pageHeader_desc {
    display: none;
  }
  .c-pageHeader_illust {
    display: none;
  }
  .l-mainColumn,
  .l-oneColumn {
    display: block;
    width: auto;
    max-width: none;
    margin: 0 10px 40px 10px;
  }
  .l-mainColumn.companyColumn,
  .l-oneColumn.companyColumn {
    margin: -20px 0 0 0;
    padding: 0;
  }
  .l-mainColumn.companyColumn .c-sectionTitle .c-sectionTitle_link,
  .l-oneColumn.companyColumn .c-sectionTitle .c-sectionTitle_link {
    margin: 0 10px 0 0;
  }
  .l-primaryColumn {
    margin: 0 0 40px 0;
  }
  .l-primaryColumn .l-article,
  .l-primaryColumn .l-monthly,
  .l-primaryColumn .l-archive {
    width: auto;
  }
  .l-secondaryColumn {
    margin: 0;
  }
  .l-sideContentsBox {
    width: auto;
  }
  .l-relatedContents {
    padding: 20px 10px;
  }
  .l-relatedContents_btn {
    margin: 20px 0 0 0;
  }
  .l-shop {
    padding: 30px 20px;
  }
  .l-shop_title {
    margin: 60px 0 30px 0;
  }
  .l-shop_title:first-child {
    margin-top: 0;
  }
  .l-shopStaff {
    display: block;
    margin: 0 0 40px 0;
  }
  .l-shopStaff .c-shopStaff {
    display: inline-block;
    width: 150px;
    margin: 0 auto 20px auto;
  }
  .l-shopStaff .c-shopStaff img {
    width: 120px;
    height: 120px;
  }
  .l-shopStaff .c-shopStaff_comment {
    top: -85px;
    right: -30px;
  }
  .c-archive {
    padding: 0 0 20px 0;
  }
  .c-archive_tmb {
    display: table-cell;
    width: 130px;
    height: auto;
  }
  .c-archive_tmb.topicsTmb {
    display: table-cell;
    width: 100px;
    height: 100px;
  }
  .c-archive_tmb.mediaTmb {
    width: 130px;
    height: 130px;
  }
  .c-archive_tmb.searchTmb {
    height: 78px;
  }
  .c-archive_tmb a {
    display: inline-block;
    height: 78px;
  }
  .c-archive_tmb a img {
    max-width: none;
    width: 130px;
    height: auto;
    overflow: hidden;
  }
  .c-archive_tmb.topicsTmb {
    width: 100px;
    height: 100px;
  }
  .c-archive_tmb.topicsTmb img {
    width: 100px;
    height: 100px;
  }
  .c-archive_body {
    padding: 0 0 0 12px;
  }
  .c-archive_body.info {
    padding: 0;
  }
  .c-archive_body.info .magazineDate {
    padding: 0;
  }
  .c-archive_body.info .c-archive_body_title {
    margin: 0;
  }
  .c-archive_body_date.topicsDate, .c-archive_body_date.smileDate, .c-archive_body_date.magazineDate {
    margin: 0 0 5px 0;
    padding: 15px 0 0 0;
  }
  .c-archive_body_date.mediaDate {
    color: #004097;
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 5px 0;
  }
  .c-archive_body_title {
    display: block;
    font-size: 13px;
    overflow: hidden;
    max-height: 52px;
    line-height: 1.4;
    margin: 0 0 8px 0;
  }
  .c-archive_body_desc {
    display: none;
  }
  .c-companyTop_title_img_1, .c-companyTop_title_img_2, .c-companyTop_title_img_3 {
    display: none;
  }
  .c-company_tmb img,
  .c-companyMessage_tmb img {
    width: 100%;
    height: auto;
  }
  .c-company_outline,
  .c-companyMessage_outline {
    margin: 20px 10px 40px 10px;
    padding: 10px;
  }
  .c-company_outline .c-companyInfo,
  .c-companyMessage_outline .c-companyInfo {
    font-size: 13px;
  }
  .c-company_outline .c-companyInfo_row,
  .c-companyMessage_outline .c-companyInfo_row {
    display: inline-block;
    width: 100%;
  }
  .c-company_outline .c-companyInfo_row_label,
  .c-companyMessage_outline .c-companyInfo_row_label {
    width: auto;
    padding: 10px 0 5px 10px;
  }
  .c-company_outline .c-companyInfo_row_desc,
  .c-companyMessage_outline .c-companyInfo_row_desc {
    margin: 0;
    padding: 0 0 10px 10px;
  }
  .c-company_outline .c-companyInfo_row:last-child,
  .c-companyMessage_outline .c-companyInfo_row:last-child {
    border-bottom: none;
  }
  .c-company_map_desc,
  .c-companyMessage_map_desc {
    font-size: 13px;
    margin: 0 20px 10px 20px;
  }
  .c-company_map_map,
  .c-companyMessage_map_map {
    display: none;
  }
  .c-company_map_btn,
  .c-companyMessage_map_btn {
    display: block;
    text-align: center;
    margin: 25px 0 0 0;
  }
  .c-company_map_btn .c-primaryBtn,
  .c-companyMessage_map_btn .c-primaryBtn {
    padding: 10px 50px;
  }
  .c-company_footerNav,
  .c-companyMessage_footerNav {
    display: block !important;
    margin: 120px 20px 0 20px;
    padding: 30px 20px;
  }
  .c-company_footerNav_image,
  .c-companyMessage_footerNav_image {
    position: relative;
    top: -61px;
    left: 0;
  }
  .c-company_footerNav_image img,
  .c-companyMessage_footerNav_image img {
    width: 220px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .c-company_footerNav_item,
  .c-companyMessage_footerNav_item {
    margin: 0 0 10px 0;
  }
  .c-company_footerNav_item:last-child,
  .c-companyMessage_footerNav_item:last-child {
    margin-bottom: 0;
  }
  .c-company_footerNav .c-primaryBtn,
  .c-companyMessage_footerNav .c-primaryBtn {
    min-width: auto;
  }
  .c-company_history,
  .c-companyMessage_history {
    padding: 10px;
  }
  .c-company_history .c-companyInfo,
  .c-companyMessage_history .c-companyInfo {
    font-size: 13px;
  }
  .c-company_history .c-companyInfo_row,
  .c-companyMessage_history .c-companyInfo_row {
    display: inline-block;
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0 10px;
  }
  .c-company_history .c-companyInfo_row_label,
  .c-companyMessage_history .c-companyInfo_row_label {
    width: 70px !important;
    padding: 0 0 5px 0;
  }
  .c-company_history .c-companyInfo_row_desc,
  .c-companyMessage_history .c-companyInfo_row_desc {
    margin: 0;
    padding: 0 0 10px 0;
  }
  .c-company_history .c-companyInfo_row:last-child,
  .c-companyMessage_history .c-companyInfo_row:last-child {
    border-bottom: none;
  }
  .c-company_history .c-primaryBtn,
  .c-companyMessage_history .c-primaryBtn {
    display: inline-block;
    font-size: 14px;
    margin: 30px 0 15px 0;
    opacity: 1;
  }
  .c-company_history .c-primaryBtn:first-child,
  .c-companyMessage_history .c-primaryBtn:first-child {
    margin: 0 0 15px 0;
  }
  .c-sideContents_searchForm input[type="text"] {
    width: 150px;
  }
  .c-sideArticle {
    display: block;
  }
  .c-sideArticle_tmb {
    width: 130px;
    height: 80px;
  }
  .c-sideArticle_tmb a {
    height: 80px;
  }
  .c-sideArticle_tmb img {
    width: 130px;
    height: auto;
    position: relative;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
  }
  .c-sideArticle_tmb.monthly {
    width: 260px;
    height: 109px;
    margin: 0 auto;
  }
  .c-sideArticle_tmb.monthly a {
    width: 260px;
    height: 109px;
  }
  .c-sideArticle_tmb.monthly img {
    width: 260px;
    height: 109px;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
  .c-sideArticle_body_date {
    display: inline-block;
    margin: 0 0 5px 0;
  }
  .c-sideArticle_body_title {
    overflow: hidden;
    height: 52px;
    font-size: 13px;
    line-height: 1.4;
    margin: 0;
  }
  .c-article_header {
    padding: 25px 20px 0 20px;
  }
  .c-article_header_date {
    font-size: 14px;
  }
  .c-article_header_date .c-categoryLabel {
    margin: 0 0 0 10px !important;
  }
  .c-article_header_title {
    font-size: 21px;
  }
  .c-article_tmb {
    width: auto;
    height: auto;
  }
  .c-article_tmb.smileTmb {
    height: 180px;
  }
  .c-article_body {
    margin: 20px;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 2;
  }
  .c-article_body h4 {
    font-size: 16px;
    line-height: 1.5;
  }
  .c-article_body p {
    font-size: 14px;
    letter-spacing: 0.3px;
  }
  .c-article_body p strong {
    display: inline-block;
    line-height: 30px;
  }
  .c-article_body img {
    max-width: 100%;
    height: auto;
    margin: 0;
  }
  .c-article_body img.alignnone {
    width: 100%;
    height: auto;
  }
  .c-article_body iframe {
    width: 414px !important;
    max-width: 100% !important;
    min-width: initial !important;
  }
  .c-article_body .thanks2017_main_img {
    width: 100%;
    height: auto;
  }
  .c-article_body .thanks2017_app_method img {
    width: auto;
  }
  .c-relatedArticle {
    margin: 0 0 20px 0;
  }
  .c-relatedArticle_tmb {
    display: block;
    width: 100%;
    height: auto;
  }
  .c-relatedArticle_tmb.topicsTmb {
    display: table-cell;
  }
  .c-relatedArticle_tmb a {
    display: block;
    height: auto;
  }
  .c-relatedArticle_tmb img {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
  .c-relatedArticle_body_date {
    font-size: 12px;
    margin: 0 0 5px 0;
  }
  .c-relatedArticle_body_title {
    overflow: hidden;
    max-height: 42px;
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
  }
  .c-relatedArticle_body_title.topics {
    max-height: 76px;
  }
  .c-relatedArticle_body_desc {
    display: none;
  }
  .c-relatedArticle_body.smileBody {
    padding: 10px 0 0 0;
  }
  .c-relatedArticle_body.magazineBody {
    padding: 10px 0 0 0;
  }
  .c-monthly_header_tmb {
    width: 100%;
    height: auto;
    margin: 0 0 10px 0;
  }
  .c-monthly_header_tmb img {
    width: 100%;
    height: auto;
  }
  .c-monthly_body {
    margin: 10px;
    padding: 0;
  }
  .c-monthly_body_title {
    font-size: 18px;
  }
  .c-monthly_body_desc p {
    font-size: 14px;
  }
  .c-monthly_body_desc img {
    width: 100%;
    height: auto;
    margin: 0;
  }
  .c-monthly_outline_row {
    width: 100%;
    padding: 15px 5px;
  }
  .c-monthly_outline_label {
    width: 70px;
    padding: 0;
  }
  .c-monthly_footer {
    margin: 30px 0 0 0;
    padding: 0 10px;
  }
  .c-monthly_footer_caution {
    font-size: 12px;
  }
  .c-sharedContents {
    width: 100%;
  }
  .c-sharedContents_balloon {
    top: -30px;
    right: -10px;
  }
  .c-sharedContents_balloon img {
    width: 95px;
    height: auto;
  }
  .c-sharedContents_item .facebookBtn {
    margin: 0 0 10px 0;
  }
  .c-categoryLabel.sideLabel.magazineLabel {
    top: 1px;
  }
  .c-categoryLabel.relatedLabel {
    right: -10px;
    border-radius: 3px 0 3px 3px;
  }
  .c-shop {
    display: block;
  }
  .c-shop_image {
    width: 100%;
    margin: 0 0 30px 0;
  }
  .c-shop_contents_title {
    font-size: 18px;
    margin: 0 0 20px 0;
  }
  .c-shopInfo_row_label {
    width: 65px;
  }
  .c-companyAbout {
    display: none;
  }
  .pager li {
    margin: 0 7px 10px 7px;
  }
  .pager li a {
    font-size: 14px;
  }
  .pager li.prev a, .pager li.next a {
    padding: 5px 7px;
  }
  .pager li.prev, .pager li.next, .pager li.active {
    padding: 5px;
  }
  .l-subFooter {
    padding: 40px 10px 90px 10px;
  }
  .l-subFooter_bnr {
    overflow: hidden;
  }
  .l-subFooter_bnr li {
    float: left;
    width: 49%;
    margin: 0;
  }
  .l-subFooter_bnr li:nth-child(2n) {
    float: right;
  }
  .l-subFooter_bnr li img {
    width: 100%;
    height: auto;
  }
  .l-subFooter_info dl {
    display: block;
  }
  .l-subFooter_info dl dd {
    margin: 5px 0 0 0;
  }
  .l-footer .l-footerContents {
    margin: 0 auto 25px auto;
  }
  .l-footer .l-footerContents_left {
    display: none;
  }
  .l-footer .l-footerContents_right {
    margin: 0;
  }
  .l-footer .l-footerContents_right .footerCopy {
    font-size: 13px;
    letter-spacing: 2px;
  }
  .l-footer .l-footerContents_right .footerCopy,
  .l-footer .l-footerContents_right .footerLogo {
    text-align: center;
    margin: 0 0 5px 0;
  }
  .l-footer .l-footerContents_right .footerCopy a img,
  .l-footer .l-footerContents_right .footerLogo a img {
    width: auto;
    height: 30px;
  }
  .pageTop {
    top: -70px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 109px;
    height: 101px;
  }
  .copyright {
    font-size: 10px;
  }
  .p-shopArticle_body_btnGroup {
    text-align: center;
  }
  /*
  =====================================================================
  *
  *    sp style
  *
  * ===================================================================
  */
  .l-spHeader {
    position: fixed;
    z-index: 9997;
    display: block;
    width: 100%;
    height: 70px;
    background: #E50012;
    border-top: 5px solid #AF1A07;
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .l-spHeader_inner {
    overflow: hidden;
    padding: 10px 10px 0 10px;
  }
  .l-spHeader_inner_logo {
    height: 48px;
  }
  .l-spHeader_inner_logo .copy {
    display: block;
    color: #FFFFFF;
    font-size: 11.5px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: 1px;
    margin: 0 0 2px 0;
  }
  .l-spHeader_inner_logo .logo {
    width: 80%;
    margin: 0;
    font-size: 10px;
  }
  .l-spHeader_inner_logo .logo img {
    width: auto;
    height: 30px;
  }
  .l-spHeader.js-hide {
    transform: translateY(-75px);
  }
  .l-spFooterNav {
    display: block;
    margin: 0;
    padding: 0 10px 20px 10px;
  }
  .l-spFooterNav li {
    width: 100%;
    list-style: none;
    border-bottom: 1px dotted #FFFFFF;
    background: url(../images/common/footer_spNav_arrow.png) no-repeat right 10px center;
    background-size: 8px;
  }
  .l-spFooterNav li:last-child {
    border-bottom: none;
  }
  .l-spFooterNav li a {
    display: block;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    padding: 12px 0;
  }
  .l-spFooterSocialNav {
    display: block;
    width: 240px;
    font-size: 0;
    margin: 0 auto;
    padding: 0;
  }
  .l-spFooterSocialNav li {
    display: inline-block;
    list-style: none;
    margin: 0 7px;
    padding: 0;
  }
  .l-spFooterSocialNav li .c-socialBtn {
    width: 45px;
    height: 45px;
    padding: 0;
    border-radius: 100px;
  }
  .l-spFooterSocialNav li .c-socialBtn.fb {
    background: #FFFFFF url(../images/common/footer_spSocialNav_fb.png) no-repeat center center/10px;
  }
  .l-spFooterSocialNav li .c-socialBtn.tw {
    background: #FFFFFF url(../images/common/footer_spSocialNav_tw.png) no-repeat center center/18px;
  }
  .l-spFooterSocialNav li .c-socialBtn.insta {
    background: #FFFFFF url(../images/common/footer_spSocialNav_insta.png) no-repeat center center/18px;
  }
  .l-spFooterSocialNav li .c-socialBtn.ameba {
    background: #FFFFFF url(../images/common/footer_spSocialNav_ameba.png) no-repeat center center/16px;
  }
  .p-spMonthlyTitle {
    display: block;
    text-align: center;
    margin: 10px 0 20px 0;
  }
  .p-spCharacterArticle {
    display: block;
    margin: 0 0 60px 0;
  }
  .p-spCharacterArticle_item {
    text-align: center;
  }
  .p-spCharacterArticle_item.characterImg {
    margin: 0 0 30px 0;
  }
  .p-spCharacterArticle_item_download {
    margin: 0;
    padding: 0;
  }
  .p-spCharacterArticle_item_download li {
    list-style: none;
    margin: 0 0 40px 0;
  }
  .p-spCharacterArticle_item_download li a {
    font-weight: bold;
  }
  .p-spCharacterArticle_item_download li a img {
    width: 60%;
    height: auto;
  }
  .p-spCharacterArticle_item_download li:last-child {
    margin: 0;
  }
  .p-spCharacterArticle:last-child {
    margin: 0;
  }
  .c-spCompanyTop_item {
    display: block;
    text-align: center;
  }
  .c-spCompanyTop_item img {
    width: 280px;
    height: auto;
  }
  .drawer-hamburger {
    display: block;
  }
  .drawer-hamburger.js-hide {
    transform: translateY(-75px);
  }
  .drawer-hamburger {
    top: 0;
    border-top: 5px solid #AF1A07;
    padding: 24px 1rem 33px 1rem;
  }
  .drawer-hamburger-icon,
  .drawer-hamburger-icon:after,
  .drawer-hamburger-icon:before {
    background: #FFFFFF;
    height: 3px;
    border-radius: 2px;
  }
  .drawer--right .drawer-hamburger,
  .drawer--right.drawer-open .drawer-nav,
  .drawer--right.drawer-open .drawer-navbar .drawer-hamburger {
    z-index: 9999;
    background: #E50012;
  }
  .drawer-overlay {
    z-index: 9997;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .drawer-menu {
    transform: none !important;
  }
  .drawer-menu_list a {
    display: block;
    font-size: 15px;
    font-weight: bold;
    color: #FFFFFF;
    padding: 10px 20px;
  }
  .drawer-nav {
    border-top: 5px solid #AF1A07;
    padding: 15px 0 0 0;
  }
  .ms-skin-default .ms-nav-next,
  .ms-skin-default .ms-nav-prev {
    display: none;
  }
}

@media screen and (max-width: 824px) {
  .p-magazineArticle {
    min-height: 390px;
  }
}

@media screen and (max-width: 699px) {
  /*
  =====================================================================
  *
  *    under 699px for SmartPhone
  *
  * ===================================================================
  */
  .l-topArticles.monthlyArticles {
    height: auto;
    padding: 0 0 30px 0;
    overflow: hidden;
  }
  .l-topArticles.magazineArticles {
    overflow: hidden;
    min-height: auto;
    margin: 0;
  }
  .l-spHeader {
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .l-spHeader.js-hide {
    transform: translateY(-75px);
  }
  .c-primaryBtn.topMagazine {
    display: block;
    width: 230px;
    margin: 0 auto 60px auto;
    text-align: center;
  }
  .c-pageHeader_title {
    line-height: 1.4;
  }
  .c-pageHeader_title img {
    width: auto;
    height: 32px;
  }
  .c-categoryLabel {
    font-size: 10px;
    padding: 6px 10px;
    margin: 0;
  }
  .c-categoryLabel.archiveLabel {
    left: 112px;
    right: auto;
  }
  .c-categoryLabel.archiveLabel.smileLabel, .c-categoryLabel.archiveLabel.magazineLabel {
    left: 142px;
  }
  .c-categoryLabel.relatedLabel {
    top: 0;
    right: 10px;
  }
  .c-categoryLabel.relatedLabel.smileLabel, .c-categoryLabel.relatedLabel.magazineLabel {
    right: 0;
    border-radius: 3px 0 3px 3px;
  }
  .c-categoryLabel.relatedLabel.topicsLabel {
    left: 160px;
    right: auto;
  }
  .c-categoryLabel.topicsLabel {
    border-radius: 3px !important;
    margin: 0 0 5px 0;
  }
  .c-categoryLabel.sideLabel {
    border-radius: 0 3px 3px 3px;
  }
  .c-categoryLabel.sideLabel.magazineLabel {
    top: 1px;
  }
  .c-archive_body_date.topicsDate, .c-archive_body_date.smileDate, .c-archive_body_date.magazineDate {
    padding: 30px 0 0 0;
  }
  .p-monthlyArticle {
    float: none;
    width: 90%;
    margin: 0 auto 20px auto;
  }
  .p-monthlyArticle:first-child {
    margin: 0 auto 20px auto;
  }
  .p-monthlyArticle:nth-child(2n) {
    float: none;
  }
  .p-monthlyArticle_tmb {
    width: 100%;
    height: auto;
  }
  .p-monthlyArticle_tmb img {
    width: 100%;
    max-width: none;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
  .p-monthlyArticle_body {
    padding: 6px 10px 15px 10px;
  }
  .p-monthlyArticle_body_desc {
    margin: 0;
    height: auto;
  }
  .p-monthlyArticle_body_desc a {
    display: none;
  }
  .p-magazineArticle {
    float: left;
    width: 46%;
    height: 210px;
    min-height: 210px;
    margin: 0 auto 20px 10px;
  }
  .p-magazineArticle:nth-child(2n) {
    float: right;
    margin: 0 10px 20px auto;
  }
  .p-magazineArticle:nth-child(4n) {
    float: right;
    margin: 0 10px 20px auto;
  }
  .p-magazineArticle:last-child {
    margin: 0 10px 20px auto;
  }
  .p-magazineArticle_tmb {
    width: 100%;
    height: 108px;
  }
  .p-magazineArticle_tmb img {
    width: 100%;
    max-width: none;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
  .p-magazineArticle_body {
    height: 80px;
    padding: 4px 10px 10px 10px;
  }
  .p-magazineArticle_body_date {
    display: none;
  }
  .p-magazineArticle_body_title {
    min-height: 58px;
    overflow: hidden;
    line-height: 1.3;
  }
  .p-magazineArticle_body_customTable, .p-magazineArticle_body_btnGroup {
    display: none;
  }
  .p-magazineArticle_body_desc {
    display: none;
  }
  .p-shopArticle {
    float: none;
    width: 95%;
    min-height: 300px;
    margin: 0 auto 20px auto;
  }
  .p-shopArticle:nth-child(2n) {
    float: none;
    margin: 0 auto 20px auto;
  }
  .p-shopArticle:nth-child(4n) {
    float: none;
    margin: 0 auto 20px auto;
  }
  .p-shopArticle:last-child {
    margin: 0 auto 20px auto;
  }
  .p-shopArticle_tmb {
    width: 100%;
    height: auto;
  }
  .p-shopArticle_tmb img {
    width: 100%;
    max-width: none;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
  .p-shopArticle_body {
    padding: 12px 12px 20px 12px;
  }
  .p-shopArticle_body_title {
    height: auto;
    max-height: 55px;
    min-height: 30px;
    margin: 0 0 10px 0;
  }
  .p-shopArticle_body_customTable, .p-shopArticle_body_btnGroup {
    display: none;
  }
  .p-shopArticle_body_desc {
    height: 42px;
    margin: 0;
  }
  .p-spCharacterArticle {
    display: block;
    margin: 0 0 60px 0;
  }
  .p-spCharacterArticle_item {
    text-align: center;
  }
  .p-spCharacterArticle_item.characterImg {
    margin: 0 0 30px 0;
  }
  .p-spCharacterArticle_item.characterImg img {
    max-width: 250px;
  }
  .p-spCharacterArticle_item.characterTxt img {
    width: 100%;
    max-width: 260px;
  }
  .p-spCharacterArticle_item.characterMovie iframe {
    width: 90%;
    height: 220px;
    margin: 0 auto;
  }
  .p-spCharacterArticle_item_download {
    margin: 0;
    padding: 0;
  }
  .p-spCharacterArticle_item_download li {
    list-style: none;
    margin: 0 0 40px 0;
  }
  .p-spCharacterArticle_item_download li a {
    font-weight: bold;
  }
  .p-spCharacterArticle_item_download li a img {
    width: 60%;
    height: auto;
  }
  .p-spCharacterArticle_item_download li:last-child {
    margin: 0;
  }
  .p-spCharacterArticle:last-child {
    margin: 0;
  }
  .p-staff {
    margin: 0 auto 40px auto;
    overflow: hidden;
  }
  .p-staff_item {
    float: left;
    margin: 0px 15px 40px 15px;
  }
  .p-staff_item img {
    width: 120px;
    height: auto;
  }
  .p-staff_item:last-child {
    margin: 0px 15px 40px 15px;
  }
  .p-staff:last-child {
    margin: 0;
  }
  .c-sectionTitle_link.magazine {
    display: none;
  }
  .c-relatedArticle_tmb {
    display: block;
    width: 100%;
    height: auto;
    vertical-align: top;
  }
  .c-relatedArticle_tmb.topicsTmb {
    display: table-cell;
    width: 140px;
    height: 140px;
  }
  .c-relatedArticle_tmb a {
    display: block;
    height: auto;
  }
  .c-relatedArticle_tmb img {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
  .c-relatedArticle_body.topicsBody {
    padding: 30px 0 0 20px;
  }
  #count_box_link {
    display: block !important;
    width: 340px !important;
    margin: 20px auto !important;
  }
  #count_box {
    background: url(/wp-content/themes/popcornpapa_2017/assets/images/top/papaday_count.png) 0 0 no-repeat !important;
    width: 340px !important;
    height: 72px !important;
    background-size: 340px !important;
  }
  #count_box_num {
    font-weight: bold !important;
    font-size: 64px !important;
    position: absolute !important;
    top: 5px !important;
    right: 40px !important;
    color: #fff !important;
    font-family: arial !important;
    line-height: 1 !important;
  }
  .today_papaday_img {
    display: block !important;
    width: 340px !important;
    margin: 20px auto !important;
  }
}

@media screen and (max-width: 479px) {
  /*
  =====================================================================
  *
  *    under 479px for SmartPhone
  *
  * ===================================================================
  */
  .p-flavor {
    overflow: hidden;
    margin: 0;
  }
  .p-flavor_item {
    float: left;
    width: 120px;
    min-height: 170px;
    margin: 0 15px 20px 15px;
  }
  .p-flavor_item a img {
    width: 120px;
    height: 120px;
  }
  .p-flavor_item:nth-child(2n) {
    float: right;
    margin: 0 15px 20px 15px;
  }
  .p-staff {
    text-align: center;
  }
  .c-relatedArticle_body {
    padding: 0;
  }
  .thanks_app_btn {
    margin: 0 !important;
  }
}

@media screen and (max-width: 374px) {
  /*
  =====================================================================
  *
  *    under 374px for SmartPhone
  *
  * ===================================================================
  */
  .l-shopStaff .c-shopStaff {
    display: inline-block;
    width: 48%;
    margin: 0 auto 20px auto;
  }
  .l-spHeader_inner_logo {
    width: auto;
  }
  .l-spHeader_inner_logo .logo img {
    height: 29px;
  }
  .l-404 .p-pageHeader_title {
    font-size: 22px;
  }
  .c-pageHeader_title img {
    width: auto;
    height: 28px;
  }
  .p-flavor {
    overflow: hidden;
    margin: 0;
  }
  .p-flavor_item {
    float: left;
    width: 120px;
    min-height: 170px;
    margin: 0 5px 20px 0;
  }
  .p-flavor_item a img {
    width: 120px;
    height: 120px;
  }
  .p-flavor_item:nth-child(2n) {
    float: right;
    margin: 0 0 20px 5px;
  }
  .p-staff {
    width: 90%;
    margin: 0 auto;
  }
  .p-staff_item {
    float: none;
    margin: 0px 5px 40px 5px;
  }
  .p-staff_item img {
    width: 150px;
    height: auto;
  }
  .p-staff_item:last-child {
    margin: 0px 5px 40px 5px;
  }
  .p-staff:last-child {
    margin: 0 auto;
  }
  .p-404_image img {
    width: 240px;
  }
  .pager li {
    margin: 0 4px 10px 4px;
  }
  .pager li a {
    font-size: 14px;
  }
  .pager li.prev a, .pager li.next a {
    padding: 5px 7px;
  }
  .pager li.prev, .pager li.next, .pager li.active {
    padding: 5px;
  }
}
