@charset "utf-8";

$color_main: #222; //基本カラー
$color_white: #FFFFFF; //白
$color_red: #E50012;//背景色（ブランドカラー）
$color_red_dark: #AF1A07;//サブヘッダー／フッター
$color_link_red: #E50012; //リンクカラー
$color_topics: #E50012; //topicsカラー
$color_smile: #EDCD2F; //smileカラー
$color_magazine: #004097; //magazineカラー

@media screen and (max-width: 899px) {


  /*
  =====================================================================
  *
  *    Default
  *
  * ===================================================================
  */
  
  html,
  body {
    width: auto;
    min-width: auto;
  }
  
  blockquote::before {
    top: -65px!important;
  }
  
  // display: none;する要素
  .c-subHeader,
  .l-header_wrap,
  .heroImage_button,
  .swiper-button-next,
  .swiper-button-next-bg,
  .swiper-button-prev,
  .swiper-button-prev-bg,
  .l-topArticles.monthlyArticles .popcorn01,
  .l-topArticles.monthlyArticles .popcorn02,
  .l-topArticles.monthlyArticles .milky,
  .l-topArticles.monthlyArticles .poprina,
  .c-footerAddress,
  .l-footerContents_right_nav,
  .c-pageHeader_bredcrumb,
  .shopMap
   {
    display: none!important;
  }
  
  // width: 100%;する要素
  .l-topArticles,
  .c-sectionTitle,
  .l-subFooter_info,
  .l-subFooter_bnr,
  .l-footerContents,
  .l-footerContents_left,
  .l-footerContents_right,
  .l-company,
  .l-about {
    width: 100%;
  }
  
  .l-header {
    min-width: auto;
    overflow: auto;
    &_inner {
      background: none;
      padding: 0;
    .l-topArticles {
      &.heroImages {
        padding: 80px 0 32px 0px;
        overflow: hidden;
        
      }
    }
    }
  }
  .c-heroImage {
    &_list {
      width: 100%;
      height: auto;
      text-align: center;
      img {
        width: 100%;
        height: auto;
        box-shadow: none;
      }
    } 
  }
  .swiper-pagination {
    &.heroImage {
      bottom: 6px;
      .swiper-pagination {
        display: none;
      }
      .swiper-pagination-bullet {
        width: 7px;
        height: 7px;
        margin: 0 5px;
      }
    }
  }
  
  .l-topSection {
    &.flavorSection {
      margin: 0 0 90px 0;
      padding: 30px 20px 50px 20px;
    }
    &.topicsSection {
      padding: 25px 0px 15px 0;
    }
    &.monthlySection {
      background-position: center top;
      padding: 30px 10px 0 10px;
    }
  }
  
  .l-topArticles {
    &.smileArticles {}
    &.monthlyArticles {
      height: auto;
      padding: 0 0 30px 0;
      overflow: hidden;
    }
    &.magazineArticles {
      overflow: hidden;
      min-height: auto;
    }
  }
  .c-primaryBtn {
    &.topMagazine {
      display: none;
    }
  }
  
  .l-flavor {
    padding: 20px;
  }
  .l-about {
    margin: -15px 0 80px;
  }
  .l-company {
    padding: 0 0 20px 0;
  }
  .l-companyTop {
    margin: 0 auto 40px auto;
  }
  .l-character {
    padding: 60px 20px 90px 20px;
  }
  .l-staff {
    padding: 20px
  }
  .l-privacy {
    font-size: 12px;
    padding: 20px;
  }
  .l-mediaArticles {
    width: auto;
  }
  .l-mediaSection {
    margin: 0 10px 30px 10px;
    padding: 20px 0;
  }
  .l-404 {
    .p-pageHeader_title {
      font-size: 26px;
    }
  }
  
  .p-flavorArticle {
    &_tmb {
      width: 125px;
      height: 125px;
      img {
        width: 125px;
        height: 125px;
      }
    }
    &_title {
      font-size: 14px;
      margin: 10px 0 5px 0;
    }
  }
  .p-topicsArticle {
    width: auto;
    padding: 0 10px;
    &_tmb {
      width: 140px;
      height: 140px;
      img {
        max-width: 140px;
        max-height: 140px;
      }
    }
    &_body {
      padding: 0 0 0 12px;
      &_date {
        display: none;
      }
      &_title {
        max-height: 40px;
        overflow: hidden;
        font-size: 15px;
        line-height: 1.4;
        margin: 3px 0 0 0;
      }
      &_desc {
        height: 54px;
        line-height: 1.4;
      }
    }
    &:last-child {
      margin: 0;
    }
  }
  .p-flavor {
    overflow: hidden;
    margin: 0;
    &_item {
      float: left;
      width: 120px;
      min-height: 170px;
      margin: 0 10px 20px 10px;
      a {
        img {
          width: 120px;
          height: 120px;
        }
      }
      &:nth-child(4n) {
        margin: 0 10px 20px 10px;
      }
    }
  }
  
  .p-monthlyArticle {
    float: left;
    width: 48%;
    margin: 0 0 20px 0;
    &:first-child {
      margin: 0 0 20px 0;
    }
    &:nth-child(2n) {
      float: right;
    }
    &_tmb {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        max-width: none;
        height: auto;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
    }
    &_body {
      padding: 8px 10px 15px 10px;
      &_title {}
      &_desc {
        margin: 0;
        height: auto;
        line-height: 1.4;
        a {
          display: none;
        }
      }
    }
  }
  .p-smileArticle {
    &:first-child {
      margin-left: 10px;
    }
    &:last-child {
      margin-right: 10px;
    }
  }
  .p-magazineArticle,
  .p-shopArticle {
    float: left;
    width: 48%;
    min-height: 415px;
    margin: 0 0 20px 10px;
    &:nth-child(2n) {
      float: right;
      margin: 0 10px 20px 0;
    }
    &:nth-child(4n) {
      float: right;
      margin: 0 10px 20px 0;
    }
    // &:nth-child(3) {
    //   margin: 0 auto 20px auto;
    // }
    &_tmb {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        max-width: none;
        height: auto;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
    }
    &_body {
      &_title {
      height: 45px;
      // overflow: hidden;
      }
      &_customTable {
        width: 100%;
        th {
          width: 80px;
        }
      }
      &_btnGroup {
        text-align: left;
        .c-primaryBtn {
          width: 140px;
          margin: 0 0 10px 0;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  .p-shopArticle {
    &_tmb {
      width: 100%;
      height: 200px;
    }
  }
  .p-aboutHero {
    height: auto;
    margin: 0 0 20px 0;
    &_image {
      width: 100%;
      height: auto;
    }
  }
  .p-aboutTitle {
    height: auto;
    margin: 0 0 30px 0;
    img {
      opacity: 1;
      width: 75%;
      height: auto;
      &.aboutAnimation1 {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
      &.aboutAnimation2,
      &.aboutAnimation3 {
        display: none;
      }
    }
  }
  .p-aboutArticle {
    display: block;
    margin: 0 0 60px 0;
    padding: 0;
    &_item {
      &.aboutContents {
        margin: 0 10px;
      }
      &.aboutTmb {
        width: 100%;
        height: auto;
        margin: 0;
        .milky,
        .choco,
        .chocoHand,
        .poprina,
        .johnny {
          display: none!important;
        }
        img {
          display: none;
        }
      }
      &_title {
        margin: 0 0 10px 0;
        img {
          width: auto;
          height: 45px;
        }
      }
      .spAboutTmb {
        display: block;
        margin: 0 0 20px 0;
        img {
        width: 100%;
        height: auto;
        }
      }
    }
    .c-primaryBtn {
      display: block;
      width: 180px;
      font-size: 13px;
      margin: 0 auto 10px auto;
      padding: 10px 20px;
    }
  }
  
  .p-characterArticle {
    display: none;  
  }
  
  .p-companyHero {
    height: 200px;
    margin: 5px 0 30px 0;
    &_image {
      width: auto;
      height: 50px;
    }
  }
  .p-mediaArticle {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px dotted #CCCCCC;
    &_tmb {
      width: 120px;
      height: 120px;
      img {
        width: 120px;
        height: 120px;
      }
    }
    &_body {
      vertical-align: top;
      &_date {
        display: block;
        margin: 0 0 5px 0;
      }
      &_title {
        overflow: hidden;
        height: 94px;
        margin: 0;
        line-height: 1.4;
        font-size: 14px;
      }
      &_desc {
        display: none;
      }
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  .p-companyRecruit {
    height: 153px;
    padding: 60px 0;
    &_title {
      font-size: 22px;
      letter-spacing: 1px;
      margin: 0 0 30px 0;
      padding: 0 40px;
    }
  }
  .p-404 {
    &_image {
      img {
        width: 300px;
      }
    }
  }
  
  .c-companyTop {
    &_item {
      display: none;
    }
  }
  
  .flavorBtn {
    font-size: 15px;
    width: 270px;
    padding: 15px 15px 15px 45px;
  }
  
  .c-sectionTitle {
    &.flavorTitle {
      width: auto;
      margin: 0 0 15px 0;
      img {
        width: auto;
        height: 35px;
      }
    }
    &_link {
      &.magazine {
        display: block;
      }
    }
    span {
      padding: 0 0 0 10px;
      img {
        width: auto;
        height: 35px;
      }
    }
    &.info {
      .c-sectionTitle_link {
        margin: 0;
      }
    }
    .c-sectionTitle_link {
      margin: 0 10px 8px 0;
    }
  }

  .c-pageHeader {
    max-width: none;
    padding: 95px 10px 0px 10px;
    &_title {
      img {
        width: auto;
        height: 35px;
      }
    }
    &_desc {
      display: none;
    }
    &_illust {
      display: none;
    }
  }
  .l-mainColumn,
  .l-oneColumn {
    display: block;
    width: auto;
    max-width: none;
    margin: 0 10px 40px 10px;
//     padding: 0 10px;
    &.companyColumn {
      margin: -20px 0 0 0;
      padding: 0;
      .c-sectionTitle {
        .c-sectionTitle_link {
          margin: 0 10px 0 0;
        }
      }
    }
  }
  .l-primaryColumn {
    margin: 0 0 40px 0;
    .l-article,
    .l-monthly,
    .l-archive {
      width: auto;
    }
  }
  .l-secondaryColumn {
    margin: 0;
  }
  .l-sideContentsBox {
    width: auto;
    
  }
  .l-relatedContents {
    padding: 20px 10px;
    &_btn {
      margin: 20px 0 0 0;
    }
  }
  .l-shop {
    padding: 30px 20px;
    &_title {
      margin: 60px 0 30px 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .l-shopStaff {
    display: block;
    margin: 0 0 40px 0;
    .c-shopStaff {
      display: inline-block;
      width: 150px;
      margin: 0 auto 20px auto;
      img {
        width: 120px;
        height: 120px;
      }
      &_comment {
        top: -85px;
        right: -30px;
      }
    }
  }
  
  .c-archive {
    padding: 0 0 20px 0;
    &_tmb {
      display: table-cell;
      width: 130px;
      height: auto;
      &.topicsTmb {
        display: table-cell;
        width: 100px;
        height: 100px;
      }
      &.mediaTmb {
        width: 130px;
        height: 130px;
      }
      &.searchTmb {
        height: 78px;
      }
      a {
        display: inline-block;
        height: 78px;
        img {
          max-width: none;
          width: 130px;
          height: auto;
          overflow: hidden;
        }
      }
      &.topicsTmb {
        width: 100px;
        height: 100px;
        img {
          width: 100px;
          height: 100px;
        }
      }
    }
    &_body {
      padding: 0 0 0 12px;
      &.info {
        padding: 0;
        .magazineDate {
          padding: 0;
        }
        .c-archive_body_title {
          margin: 0;
        }
      }
      &_date {
        &.topicsDate,
        &.smileDate,
        &.magazineDate {
          margin: 0 0 5px 0;
          padding: 15px 0 0 0;
        }
        &.mediaDate {
          color: $color_magazine;
          font-size: 12px;
          font-weight: bold;
          margin: 0 0 5px 0;
        }
      }
      &_title {
        display: block;
        font-size: 13px;
        overflow: hidden;
        max-height: 52px;
        line-height: 1.4;
        margin: 0 0 8px 0;
      }
      &_desc {
        display: none;
      }
    }
  }
  
  .c-companyTop {
    &_title {
      &_img {
        &_1,
        &_2,
        &_3 {
          display: none;
        }
      }
    }
  }
  
  .c-company,
  .c-companyMessage {
    &_tmb {
      img {
        width: 100%;
        height: auto;
      }
    }
    &_outline {
      margin: 20px 10px 40px 10px;
      padding: 10px;
      .c-companyInfo {
        font-size: 13px;
        &_row {
          display: inline-block;
          width: 100%;
          &_label {
            width: auto;
            padding: 10px 0 5px 10px;
          }
          &_desc {
            margin: 0;
            padding: 0 0 10px 10px;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    &_map {
      &_desc {
        font-size: 13px;
        margin: 0 20px 10px 20px;
      }
      &_map {
        display: none;
      }
      &_btn {
        display: block;
        text-align: center;
        margin: 25px 0 0 0;
        .c-primaryBtn {
          padding: 10px 50px;
        }
      }
    }
    &_footerNav {
      display: block!important;
      margin: 120px 20px 0 20px;
      padding: 30px 20px;
      &_image {
        position: relative;
        top: -61px;
        left: 0;
        img {
          width: 220px;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &_item {
        margin: 0 0 10px 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .c-primaryBtn {
        min-width: auto;
      }
    }
    &_history {
      padding: 10px;
      .c-companyInfo {
        font-size: 13px;
        &_row {
          display: inline-block;
          width: 100%;
          margin: 0 0 10px 0;
          padding: 0 10px;
          &_label {
            width: 70px!important;
            padding: 0 0 5px 0;
          }
          &_desc {
            margin: 0;
            padding: 0 0 10px 0;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
      .c-primaryBtn {
        display: inline-block;
        font-size: 14px;
        margin: 30px 0 15px 0;
        opacity: 1;
        &:first-child {
          margin: 0 0 15px 0;
        }
      }
    }
  }
  
  .c-sideContents {
    &_searchForm {
      input[type="text"] {
        width: 150px;
      }
    }
  }
  
  .c-sideArticle {
    display: block;
    &_tmb {
      width: 130px;
      height: 80px;
      a {
        height: 80px;
      }
      img {
        width: 130px;
        height: auto;
        position: relative;
        top: 50%;
        left: 0;
        transform: translate(0%, -50%);
      }
      &.monthly {
        width: 260px;
        height: 109px;
        margin: 0 auto;
        a {
          width: 260px;
          height: 109px;
        }
        img {
          width: 260px;
          height: 109px;
          position: relative;
          top: 0;
          left: 0;
          transform: none;
        }
      }
    }
    &_body {
      &_date {
        display: inline-block;
        margin: 0 0 5px 0;
      }
      &_title {
        overflow: hidden;
        height: 52px;
        font-size: 13px;
        line-height: 1.4;
        margin: 0;
      }
    }
  }
  
  .c-article {
    &_header {
      padding: 25px 20px 0 20px;
      &_date {
        font-size: 14px;
        .c-categoryLabel {
          margin: 0 0 0 10px!important;
        }
      }
      &_title {
        font-size: 21px;
      }
    }
    &_tmb {
      width: auto;
      height: auto;
      &.smileTmb {
        height: 180px;
      }
    }
    &_body {
      margin: 20px;
      font-size: 14px;
      letter-spacing: 0.3px;
      line-height: 2;
      h4 {
        font-size: 16px;
        line-height: 1.5;
      }
      p {
        font-size: 14px;
        letter-spacing: 0.3px;
        strong {
          display: inline-block;
          line-height: 30px;
        }
      }
      img {
        max-width: 100%;
        height: auto;
        margin: 0;
        &.alignnone {
          width: 100%;
          height: auto;
        }
      }
      iframe {
        width: 414px!important;
        max-width: 100%!important;
        min-width: initial!important;
      }
      .thanks2017_main_img {
        width: 100%;
        height: auto;
      }
      .thanks2017_app_method {
        img {
          width: auto;
        }
      }
    }
  }
  
  .c-relatedArticle {
    margin: 0 0 20px 0;
    &_tmb {
      display: block;
      width: 100%;
      height: auto;
      &.topicsTmb {
        display: table-cell;
      }
      a {
        display: block;
        height: auto;
      }
      img {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
    }
    &_body {
      &_date {
        font-size: 12px;
        margin: 0 0 5px 0;
      }
      &_title {
        overflow: hidden;
        max-height: 42px;
        font-size: 14px;
        line-height: 1.4;
        margin: 0;
        &.topics {
          max-height: 76px;  
        }
      }
      &_desc {
        display: none;
      }
      &.smileBody {
        padding: 10px 0 0 0;
      }
      &.magazineBody {
        padding: 10px 0 0 0;
      }
    }
  }
  
  .c-monthly {
    &_header {
      &_tmb {
        width: 100%;
        height: auto;
        margin: 0 0 10px 0;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    &_body {
      margin: 10px;
      padding: 0;
      &_title {
        font-size: 18px;
      }
      &_desc {
        p {
          font-size: 14px;
        }
        img {
          width: 100%;
          height: auto;
          margin: 0;
        }
      }
    }
    &_outline {
     &_row {
       width: 100%;
       padding: 15px 5px;
     }
     &_label {
       width: 70px;
       padding: 0;
     }
    }
    &_footer {
      margin: 30px 0 0 0;
      padding: 0 10px;
      &_caution {
        font-size: 12px;
      }
    }
  }
  
  .c-sharedContents {
    width: 100%;
    &_balloon {
      top: -30px;
      right: -10px;
      img {
        width: 95px;
        height: auto;
      }
    }
    &_item {
      .facebookBtn {
        margin: 0 0 10px 0;
      }
    }
  }
  
  .c-categoryLabel {
    &.sideLabel {
      &.magazineLabel {
        top: 1px;
      }
    }
    &.relatedLabel {
      right: -10px;
      border-radius: 3px 0 3px 3px;
    }
  }
  
  .c-shop {
    display: block;
    &_image {
      width: 100%;
      margin: 0 0 30px 0;
    }
    &_contents {
      &_title {
        font-size: 18px;
        margin: 0 0 20px 0;
      }
    }
  }
  .c-shopInfo {
    &_row {
      &_label {
        width: 65px;
      }
    }
  }
  
  .c-companyAbout {
    display: none;
  }
  
  .pager {
    li {
      margin: 0 7px 10px 7px;
      a {
        font-size: 14px;
      }
      &.prev,
      &.next {
        a {
          padding: 5px 7px;
        }
      }
      &.prev,
      &.next,
      &.active {
        padding: 5px;
      }
    }
  }
  
  .l-subFooter {
    padding: 40px 10px 90px 10px;
    &_bnr {
      overflow: hidden;
      li {
        float: left;
        width: 49%;
        margin: 0;
        &:nth-child(2n) {
          float: right;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    &_info {
      dl {
        display: block;
        dd {
          margin: 5px 0 0 0;
        }
      }
    }
  }
  
  
  .l-footer {
    .l-footerContents {
      margin: 0 auto 25px auto;
      &_left {
        display: none;
      }
      &_right {
        margin: 0;
        .footerCopy {
          font-size: 13px;
          letter-spacing: 2px;
        }
        .footerCopy,
        .footerLogo {
          text-align: center;
          margin: 0 0 5px 0;
          a {
            img {
              width: auto;
              height: 30px;
            }
          }
        }
      }
    }
  }
  
  .pageTop {
    top: -70px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 109px;
    height: 101px;
  }
  
  .copyright {
    font-size: 10px;
  }
  
  .p-shopArticle_body_btnGroup{
    text-align: center;
  }
  
  /*
  =====================================================================
  *
  *    sp style
  *
  * ===================================================================
  */
  
  
  .l-spHeader {
    position: fixed;
    z-index: 9997;
    display: block;
    width: 100%;
    height: 70px;
    background: $color_red;
    border-top: 5px solid $color_red_dark;
    transition: all .6s cubic-bezier(0.19, 1, 0.22, 1);
    &_inner {
      overflow: hidden;
      padding: 10px 10px 0 10px;
      &_logo {
        height: 48px;
        .copy {
          display: block;
          color: $color_white;
          font-size: 11.5px;
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: 1px;
          margin: 0 0 2px 0;
        }
        .logo {
          width: 80%;
          margin: 0;
          font-size: 10px;
          img {
            width: auto;
            height: 30px;
          }
        }
      }
    }
    &.js-hide {
      transform: translateY(-75px);
    }
  }
  
  .l-spFooterNav {
    display: block;
    margin: 0;
    padding: 0 10px 20px 10px;
    li {
      width: 100%;
      list-style: none;
      border-bottom: 1px dotted $color_white;
      background: url(../images/common/footer_spNav_arrow.png) no-repeat right 10px center;
      background-size: 8px;
      &:last-child {
        border-bottom: none;
      }
      a {
        display: block;
        color: $color_white;
        font-size: 14px;
        font-weight: bold;
        padding: 12px 0;
      }
    }
  }
  .l-spFooterSocialNav {
    display: block;
    width: 240px;
    font-size: 0;
    margin: 0 auto;
    padding: 0;
    li {
      display: inline-block;
      list-style: none;
      margin: 0 7px;
      padding: 0;
      .c-socialBtn {
        width: 45px;
        height: 45px;
        padding: 0;
        border-radius: 100px;
        &.fb,
        &.tw,
        &.insta,
        &.ameba {}
        &.fb {
          background: $color_white url(../images/common/footer_spSocialNav_fb.png) no-repeat center center / 10px;
        }
        &.tw {
          background: $color_white url(../images/common/footer_spSocialNav_tw.png) no-repeat center center / 18px;
        }
        &.insta {
          background: $color_white url(../images/common/footer_spSocialNav_insta.png) no-repeat center center / 18px;
        }
        &.ameba {
          background: $color_white url(../images/common/footer_spSocialNav_ameba.png) no-repeat center center / 16px;
        }
      }
    }
  }
  
  .p-spMonthlyTitle {
    display: block;
    text-align: center;
    margin: 10px 0 20px 0;
  }
  .p-spCharacterArticle {
    display: block;
    margin: 0 0 60px 0;
    &_item {
      text-align: center;
      &.characterImg {
        margin: 0 0 30px 0;
      }
      &.characterTxt {}
      &_download {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          margin: 0 0 40px 0;
          a {
            font-weight: bold;
            img {
              width: 60%;
              height: auto;
            }
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    &:last-child {
      margin: 0;
    }
  }
  
  .c-spCompanyTop {
    &_item {
      display: block;
      text-align: center;
      img {
        width: 280px;
        height: auto;
      }
    }
  }
  
  // ドロワーメニュー
  .drawer-hamburger {
    display: block;
    &.js-hide {
      transform: translateY(-75px);
    }
  }
  .drawer-hamburger {
    top: 0;
    border-top: 5px solid $color_red_dark;
    padding: 24px 1rem 33px 1rem;
  }
  .drawer-hamburger-icon,
  .drawer-hamburger-icon:after,
  .drawer-hamburger-icon:before {
    background: $color_white;
    height: 3px;
    border-radius: 2px;
  }
  .drawer--right .drawer-hamburger,
  .drawer--right.drawer-open .drawer-nav,
  .drawer--right.drawer-open .drawer-navbar .drawer-hamburger {
    z-index: 9999;
    background: $color_red;
  }
  .drawer-overlay {
    z-index: 9997;
    background-color: rgba(0,0,0,0.7);
  }
  .drawer-menu {
    transform: none!important;
    &_list {
      a {
        display: block;
        font-size: 15px;
        font-weight: bold;
        color: $color_white;
        padding: 10px 20px;
      }
    }
  }
  .drawer-nav {
    border-top: 5px solid $color_red_dark;
    padding: 15px 0 0 0;
  }
  .drawer--right .drawer-nav {
//     transition: all .6s cubic-bezier(0.19, 1, 0.22, 1);
  }
  
  // 店舗情報のスライド矢印を消す
  .ms-skin-default .ms-nav-next,
  .ms-skin-default .ms-nav-prev {
    display: none;
  }

}


@media screen and (max-width: 824px) {
  .p-magazineArticle{
    min-height: 390px;
  }
}


@media screen and (max-width: 699px) {
  /*
  =====================================================================
  *
  *    under 699px for SmartPhone
  *
  * ===================================================================
  */
  
  .l-topArticles {
    &.smileArticles {}
    &.monthlyArticles {
      height: auto;
      padding: 0 0 30px 0;
      overflow: hidden;
    }
    &.magazineArticles {
      overflow: hidden;
      min-height: auto;
      margin: 0;
    }
  }
  .l-spHeader {
    transition: all .6s cubic-bezier(0.19, 1, 0.22, 1);
    &.js-hide {
      transform: translateY(-75px);
    }
  }
  
  .c-primaryBtn {
    &.topMagazine {
      display: block;
      width: 230px;
      margin: 0 auto 60px auto;
      text-align: center;
    }
  }
  
  .c-pageHeader {
    &_title {
      line-height: 1.4;
      img {
        width: auto;
        height: 32px;
      }
    }
  }
  
  .c-categoryLabel {
    font-size: 10px;
    padding: 6px 10px;
    margin: 0;
    &.archiveLabel {
      &.smileLabel,
      &.magazineLabel {
        left: 142px;
      }
      left: 112px;
      right: auto;
    }
    &.relatedLabel {
      top: 0;
      right: 10px;
      &.smileLabel,
      &.magazineLabel {
        right: 0;
        border-radius: 3px 0 3px 3px;
      }
      &.topicsLabel {
        left: 160px;
        right: auto;
      }
    }
    &.topicsLabel {
      border-radius: 3px!important;
      margin: 0 0 5px 0;
    }
    &.sideLabel {
      border-radius: 0 3px 3px 3px;
      &.magazineLabel {
        top: 1px;
      }
    }
  }
  .c-archive {
    &_body {
      &_date {
        &.topicsDate,
        &.smileDate,
        &.magazineDate {
          padding: 30px 0 0 0;
        }
      }
    }
  }
  
  .p-monthlyArticle {
    float: none;
    width: 90%;
    margin: 0 auto 20px auto;
    &:first-child {
      margin: 0 auto 20px auto;
    }
    &:nth-child(2n) {
      float: none;
    }
    &_tmb {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        max-width: none;
        height: auto;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
    }
    &_body {
      padding: 6px 10px 15px 10px;
      &_title {}
      &_desc {
        margin: 0;
        height: auto;
        a {
          display: none;
        }
      }
    }
  }
  .p-magazineArticle {
    float: left;
    width: 46%;
    height: 210px;
    min-height: 210px;
    margin: 0 auto 20px 10px;
    &:nth-child(2n) {
      float: right;
      margin: 0 10px 20px auto;
    }
    &:nth-child(4n) {
      float: right;
      margin: 0 10px 20px auto;
    }
    &:last-child {
      margin: 0 10px 20px auto;
    }
    &_tmb {
      width: 100%;
      height: 108px;
      img {
        width: 100%;
        max-width: none;
        height: auto;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
    }
    &_body {
      height: 80px;
      padding: 4px 10px 10px 10px;
      &_date {
        display: none;
      }
      &_title {
      min-height: 58px;
      overflow: hidden;
      line-height: 1.3;
      }
      &_customTable,
      &_btnGroup {
        display: none;
      }
      &_desc {
        display: none;
      }
    }
  }
  .p-shopArticle {
    float: none;
    width: 95%;
    min-height: 300px;
    margin: 0 auto 20px auto;
    &:nth-child(2n) {
      float: none;
      margin: 0 auto 20px auto;
    }
    &:nth-child(4n) {
      float: none;
      margin: 0 auto 20px auto;
    }
    &:last-child {
      margin: 0 auto 20px auto;
    }
    &_tmb {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        max-width: none;
        height: auto;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
    }
    &_body {
      padding: 12px 12px 20px 12px;
      &_title {
      height: auto;
      max-height: 55px;
      min-height: 30px;
      // overflow: hidden;
      margin: 0 0 10px 0;
      }
      &_customTable,
      &_btnGroup {
        display: none;
      }
      &_desc {
        height: 42px;
        margin: 0;
      }
    }
  }
  
  .p-spCharacterArticle {
    display: block;
    margin: 0 0 60px 0;
    &_item {
      text-align: center;
      &.characterImg {
        margin: 0 0 30px 0;
        img {
          max-width: 250px;
        }
      }
      &.characterTxt {
        img {
          width: 100%;
          max-width: 260px;
        }
      }
      &.characterMovie {
        iframe {
          width: 90%;
          height: 220px;
          margin: 0 auto;
        }
      }
      &_download {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          margin: 0 0 40px 0;
          a {
            font-weight: bold;
            img {
              width: 60%;
              height: auto;
            }
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    &:last-child {
      margin: 0;
    }
  }
  .p-staff {
    margin: 0 auto 40px auto;
    overflow: hidden;
    &_item {
      float: left;
      margin: 0px 15px 40px 15px;
      img {
        width: 120px;
        height: auto;
      }
      &:last-child {
        margin: 0px 15px 40px 15px;
      }
    }
    &:last-child {
      margin: 0;
    }
  }
  
  .c-sectionTitle {
    &_link {
      &.magazine {
        display: none;
      }
    }
  }
  .c-relatedArticle {
    &_tmb {
      display: block;
      width: 100%;
      height: auto;
      vertical-align: top;
      &.topicsTmb {
        display: table-cell;
        width: 140px;
        height: 140px;
      }
      a {
        display: block;
        height: auto;
      }
      img {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
    }
    &_body {
      &.topicsBody {
        padding: 30px 0 0 20px;
      }
    }
  }

  #count_box_link {
    display: block!important;
    width: 340px!important;
    margin: 20px auto!important;
  }
  #count_box {
    background: url(/wp-content/themes/popcornpapa_2017/assets/images/top/papaday_count.png) 0 0 no-repeat!important;
    width: 340px!important;
    height: 72px!important;
    background-size: 340px!important;
  }
  #count_box_num {
    font-weight: bold!important;
    font-size: 64px!important;
    position: absolute!important;
    top: 5px!important;
    right: 40px!important;
    color: #fff!important;
    font-family: arial!important;
    line-height: 1!important;
  }
  .today_papaday_img {
    display: block!important;
    width: 340px!important;
    margin: 20px auto!important;
  }

}



@media screen and (max-width: 479px) {
  /*
  =====================================================================
  *
  *    under 479px for SmartPhone
  *
  * ===================================================================
  */
  
  .p-flavor {
    overflow: hidden;
    margin: 0;
    &_item {
      float: left;
      width: 120px;
      min-height: 170px;
      margin: 0 15px 20px 15px;
      a {
        img {
          width: 120px;
          height: 120px;
        }
      }
      &:nth-child(2n) {
        float: right;
        margin: 0 15px 20px 15px;
      }
    }
  }
  .p-staff {
    text-align: center;
  }
  
  .c-relatedArticle {
    &_body {
      padding: 0;
    }
  }
  
  .thanks_app_btn {
    margin: 0!important;
  }
  
}



@media screen and (max-width: 374px) {
  /*
  =====================================================================
  *
  *    under 374px for SmartPhone
  *
  * ===================================================================
  */
  
  .l-shopStaff {
    .c-shopStaff {
      display: inline-block;
      width: 48%;
      margin: 0 auto 20px auto;
    }
  }
  .l-spHeader {
    &_inner {
      &_logo {
        width: auto;
        .logo {
          img {
            height: 29px;
          }
        } 
      }
    }
  }
  .l-404 {
    .p-pageHeader_title {
      font-size: 22px;
    }
  }
  
  .c-pageHeader {
    &_title {
      img {
        width: auto;
        height: 28px;
      }
    }
  }
  
  .p-flavor {
    overflow: hidden;
    margin: 0;
    &_item {
      float: left;
      width: 120px;
      min-height: 170px;
      margin: 0 5px 20px 0;
      a {
        img {
          width: 120px;
          height: 120px;
        }
      }
      &:nth-child(2n) {
        float: right;
        margin: 0 0 20px 5px;
      }
    }
  }
  .p-staff {
    width: 90%;
    margin: 0 auto;
    &_item {
      float: none;
      margin: 0px 5px 40px 5px;
      img {
        width: 150px;
        height: auto;
      }
      &:last-child {
        margin: 0px 5px 40px 5px;
      }
    }
    &:last-child {
      margin: 0 auto;
    }
  }
  .p-404 {
    &_image {
      img {
        width: 240px;
      }
    }
  }
 
  .pager {
    li {
      margin: 0 4px 10px 4px;
      a {
        font-size: 14px;
      }
      &.prev,
      &.next {
        a {
          padding: 5px 7px;
        }
      }
      &.prev,
      &.next,
      &.active {
        padding: 5px;
      }
    }
  }






}


